<template>
  <div :class="isMobile ? 'm-prophet':'prophet'">
    <div :class="isMobile ? 'm-banner':'banner'">
      <img src="../../assets/image/prophet/prophet-banner.png" alt="">
      <div class="white" :class="isMobile ? 'm-talents-text':'talents-text'">
        <div :class="isMobile ? 'm-box-all':'box-all'">
          <div :class="isMobile ? 'm-box-foot':'box-foot'"></div>
        </div>
        <div :class="isMobile ? 'm-title':'title'">先知</div>
        <div :class="isMobile ? 'm-description':'description'">出海决策咨询、市场分析匹配、供应链选品履约</div>
      </div>
    </div>
    <div :class="isMobile ? 'm-contact':'contact'">
      <div :class="isMobile ? 'm-img-bg':'img-bg'">
        <div :class="isMobile ? 'm-contact-title':'contact-title'">“跨境电商+产业带”出海决策</div>
        <ul :class="isMobile ?'m-sailing':'sailing'">
          <li v-for="(item, index) in list" :key="index">
            <div :class="isMobile ? 'm-box': 'box'">
              <div class="icon"><img :src="item.img" alt=""></div>
              <div class="text">{{ item.text }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div :class="isMobile ? 'm-trend':'trend'">
      <div :class="isMobile ? 'm-title':'title'">商机洞察 趋势跟踪</div>
      <ul :class="isMobile ? 'm-sailing':'sailing'">
        <li v-for="(item, index) in trendList" :key="index">
          <div class="box">
            <div class="small-title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div :class="isMobile ? 'm-analysis':'analysis'">
      <div :class="isMobile ? 'm-title':'title'">市场分析</div>
      <div :class="isMobile ? 'm-analysis-chart':'analysis-chart'">
        <img src="../../assets/image/prophet/analysis.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { isMobileDevice } from "../../utils/detectDevice";
import one from '../../assets/image/prophet/one.png'
import two from '../../assets/image/prophet/two.png'
import three from '../../assets/image/prophet/three.png'
import four from '../../assets/image/prophet/four.png'
export default {
  name: "prophet",
  data() {
    return {
      one,
      two,
      three,
      four,
      isMobile: isMobileDevice(),
      list: [
        { id:'1', img: one, text: '打造产品带品牌属性，遴选国货优品出海' },
        { id:'2', img: two, text: '品类、市场、竞品、准入等维度分析产业带特色产品的出海可能性' },
        { id:'3', img: three, text: '打造“跨境电商+产业带”的跨境直播选品基地' },
        { id:'4', img: four, text: '整合生态资源，打造适合产业带出海的赋能矩阵' }
      ],
      trendList: [
        { id: '1', title: '数据驱动，精准选品', text: '汇聚海量数据，多维度分析，帮助商家快速找到潜力产品' },
        { id: '2', title: '商机洞察，一手掌握', text: '洞察行业趋势，捕捉市场动态，助力商家精准选品' },
        { id: '3', title: '助力商家，赢在选品', text: '为商家提供全方位选品服务，助力商家提升选品效率和决策准确度' },
        { id: '4', title: '趋势跟踪，不失良机', text: '实时监控市场变化，智能预测未来走势，为商家提供决策参考' }
      ],
    }
  }
}
</script>

<style scoped lang="less">
.prophet {
  .banner {
    width: 100%;
    height: 960px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .talents-text {
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      width: 1280px;
      margin: 0 auto;
      color: white;
      .title {
        font: 80px AlimamaShuHeiTi;
        font-weight: 700;
      }
      .description {
        width: 960px;
        font-size: 30px;
        font-weight: 400;
        margin: 60px auto 0;
      }
      .box-all{
        width: 180px;
        height: 160px;
        border-top: 8px solid #ffffff;
        border-bottom: 8px solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -15%;
        left: 50%;
        transform: translateX(-50%);
      }
      .box-all:before, .box-all:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        top: 0;
      }
      .box-all:before, .box-foot:before{
        border-left: 8px solid #ffffff;
        left: 0px;
      }
      .box-all:after,.box-foot:after{
        border-right: 8px solid #ffffff;
        right: 0;
      }
      .box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .box-foot:before, .box-foot:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        bottom: 0;
      }
    }
  }
  .contact {
    width: 100%;
    height: 960px;
    background: #102867;
    .img-bg {
      width: 1280px;
      height: 960px;
      background: url("../../assets/image/prophet/sailing-decision.png") no-repeat center;
      background-size: auto 100%;
      margin: 0 auto;
      overflow: hidden;
      .contact-title {
        width: 1280px;
        color: white;
        font: 60px AlimamaShuHeiTi;
        font-weight: 700;
        margin: 100px auto 150px;
        text-align: center;
      }
      .sailing {
        width: 1280px;
        height: 330px;
        margin: 0 auto;
        li {
          float: left;
          width: 290px;
          height: 330px;
          background-color: rgba(255, 255, 255, 0.15);
          margin-right: 40px;
          border-radius: 4px;
          .box {
            width: 290px;
            height: 330px;
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            text-align: center;
            .icon {
              width: 60px;
              height: 60px;
              margin-bottom: 60px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              width: 250px;
              font-size: 16px;
              color: white;
              line-height: 24px;
            }
          }
        }
        li:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .trend {
    width: 100%;
    height: 960px;
    background-color: #F8F8F8;
    overflow: hidden;
    .title {
      width: 1280px;
      font: 60px AlimamaShuHeiTi;
      color: #11142C;
      text-align: center;
      margin: 100px auto;
    }
    .sailing {
      width: 1280px;
      height: 580px;
      margin: 0 auto;
      li {
        float: left;
        width: 290px;
        height: 580px;
        margin-right: 40px;
        background: url("../../assets/image/prophet/trend-1.png");
        background-size: 100%;
        color: white;
        .box {
          width: 290px;
          height: 580px;
          display:flex;
          flex-direction:column;
          justify-content:center;
          align-items:center;
          text-align: center;
          .small-title {
            font-size: 22px;
            margin-bottom: 40px;
          }
          .text {
            width: 160px;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      li:nth-child(2) {
        background: url("../../assets/image/prophet/trend-2.png");
        background-size: 100%;
      }
      li:nth-child(3) {
        background: url("../../assets/image/prophet/trend-3.png");
        background-size: 100%;
      }
      li:last-child {
        margin-right: 0;
        background: url("../../assets/image/prophet/trend-4.png");
        background-size: 100%;
      }
    }
  }
  .analysis {
    width: 100%;
    height: 1900px;
    background-color: #ffffff;
    overflow: hidden;
    .title {
      width: 1280px;
      font: 60px AlimamaShuHeiTi;
      color: #11142C;
      text-align: center;
      margin: 100px auto;
    }
    .analysis-chart {
      width: 1280px;
      height: 1510px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.m-prophet {
  .m-banner {
    width: 100%;
    height: 5rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .m-talents-text {
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      margin: 0 .8rem;
      .m-title {
        font-size: .5rem;
        font-family: AlimamaShuHeiTi;
      }
      .m-description {
        font-size: .24rem;
        margin: .5rem auto 0;
        width: 90%;
      }
      .m-box-all{
        width: 1.25rem;
        height: 1rem;
        border-top: .05rem solid #ffffff;
        border-bottom: .05rem solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -7.5%;
        left: 50%;
        transform: translateX(-50%);
      }
      .m-box-all:before, .m-box-all:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        top: 0;
      }
      .m-box-all:before, .m-box-foot:before{
        border-left: .05rem solid #ffffff;
        left: 0px;
      }
      .m-box-all:after,.m-box-foot:after{
        border-right: .05rem solid #ffffff;
        right: 0;
      }
      .m-box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .m-box-foot:before, .m-box-foot:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        bottom: 0;
      }
    }
  }
  .m-contact {
    width: 100vw;
    height: 8.2rem;
    background: #102867;
    .m-img-bg{
      width: 100%;
      height: 100%;
      background: url("../../assets/image/prophet/sailing-decision.png") no-repeat center center;
      background-size: 160% auto;
      overflow: hidden;
      .m-contact-title {
        font: .55rem AlimamaShuHeiTi;
        color: white;
        text-align: center;
        margin: .5rem auto;
      }
      .m-sailing {
        width: 95%;
        height: 6rem;
        margin: 0 auto;
        li {
          width: 49%;
          height: 49%;
          background-color: rgba(255, 255, 255, 0.2);
          float: left;
          margin-right:  1.5%;
          margin-top: 1.5%;
          border-radius: .1rem;
          .m-box {
            width: 100%;
            height: 100%;
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            text-align: center;
            .icon {
              width: 1rem;
              height: 1rem;
              margin-bottom: .2rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              width: 95%;
              font-size: .28rem;
              color: white;
            }
          }
        }
        li:nth-child(1), li:nth-child(2) {
          margin-top: 0;
        }
        li:nth-child(2), li:nth-child(4) {
          margin-right: 0;
        }
      }
    }
  }
  .m-trend {
    width: 100vw;
    height: 16rem;
    background: #f8f8f8;
    overflow: hidden;
    .m-title {
      font: .55rem AlimamaShuHeiTi;
      color: white;
      text-align: center;
      margin: .5rem auto;
      color: #11142C;
    }
    .m-sailing {
      width: 90%;
      height: 14rem;
      margin: 0 auto;
      li {
        width: 48.89%;
        height: 48.89%;
        float: left;
        margin-right: .1rem;
        margin-bottom: .1rem;
        background: url("../../assets/image/prophet/trend-1.png");
        background-size: 100% auto;
        .box {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          .small-title {
            font-size: .35rem;
            margin-bottom: .5rem;
          }
          .text {
            width: 65%;
            font-size: .28rem;
          }
        }
      }
      li:nth-child(2) {
        background: url("../../assets/image/prophet/trend-2.png");
        background-size: 100% auto;
      }
      li:nth-child(3) {
        background: url("../../assets/image/prophet/trend-3.png");
        background-size: 100% auto;
      }
      li:last-child {
        background: url("../../assets/image/prophet/trend-4.png");
        background-size: 100% auto;
      }
    }
  }
  .m-analysis {
    width: 100vw;
    height: 13rem;
    background: #f8f8f8;
    overflow: hidden;
    .m-title {
      font: .55rem AlimamaShuHeiTi;
      color: white;
      text-align: center;
      margin: 0 auto .5rem;
      color: #11142C;
    }
    .m-analysis-chart {
      width: 95%;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
